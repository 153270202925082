// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-autocomplete-address-js": () => import("./../../../src/pages/autocomplete-address.js" /* webpackChunkName: "component---src-pages-autocomplete-address-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-paypal-react-js": () => import("./../../../src/pages/paypal-react.js" /* webpackChunkName: "component---src-pages-paypal-react-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-schedule-js": () => import("./../../../src/pages/schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */),
  "component---src-pages-snake-js": () => import("./../../../src/pages/snake.js" /* webpackChunkName: "component---src-pages-snake-js" */),
  "component---src-pages-submitted-js": () => import("./../../../src/pages/submitted.js" /* webpackChunkName: "component---src-pages-submitted-js" */),
  "component---src-templates-project-project-js": () => import("./../../../src/templates/Project/Project.js" /* webpackChunkName: "component---src-templates-project-project-js" */)
}

